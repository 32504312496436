.leftPanelContainer {
    padding: 20px;
    margin: 20px;
    width: 270px;
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 200;
}

.leftPanelContent {
    max-height: 40rem;
    width: 100%;
    overflow-y: scroll;
}

.searchBarContainer {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.searchBarContainer {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}
