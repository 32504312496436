.nature-icon {
  color: #03fc6f !important;
  background-color: transparent !important;
}

.nature-icon-highlighted {
  color: #03fc6f !important;
  background-color: #03fc6f33 !important;
}

.nature-icon-hover:hover {
  background-color: #03fc6f33 !important;
}

.museums-icon {
  color: #000000 !important;
  background-color: transparent !important;
}

.museums-icon-highlighted {
  color: #000000 !important;
  background-color: #00000033 !important;
}

.museums-icon-hover:hover {
  background-color: #00000033 !important;
}

.poi-icon {
  color: #0352fc !important;
  background-color: transparent !important;
}

.poi-icon-highlighted {
  color: #0352fc !important;
  background-color: #0352fc33 !important;
}

.poi-icon-hover:hover {
  background-color: #0352fc33 !important;
}

.entertainment-icon {
  color: #fc0303 !important;
  background-color: transparent !important;
}

.entertainment-icon-highlighted {
  color: #fc0303 !important;
  background-color: #fc030333 !important;
}

.entertainment-icon-hover:hover {
  background-color: #fc030333 !important;
}

.art-icon {
  color: #49b1f2 !important;
  background-color: transparent !important;
}

.art-icon-highlighted {
  color: #49b1f2 !important;
  background-color: #49b1f233 !important;
}

.art-icon-hover:hover {
  background-color:#49b1f233 !important;
}

.shopping-icon {
  color: #fce303 !important;
  background-color: transparent !important;
}

.shopping-icon-highlighted {
  color: #fce303 !important;
  background-color: #fce30333 !important;
}

.shopping-icon-hover:hover {
  background-color: #fce30333 !important;
}

.nourishment-icon {
  color: #92532a !important;
  background-color: transparent !important;
}

.nourishment-icon-highlighted {
  color: #92532a !important;
  background-color: #92532a33 !important;
}

.nourishment-icon-hover:hover {
  background-color: #92532a33 !important;
}

.offbeat-icon {
  color: #2adabc !important;
  background-color: transparent !important;
}

.offbeat-icon-highlighted {
  color: #2adabc !important;
  background-color: #2adabc33 !important;
}

.offbeat-icon-hover:hover {
  background-color: #2adabc33 !important;
}

.landmark-icon {
  color: #f18155 !important;
  background-color: transparent !important;
}

.landmark-icon-highlighted {
  color: #f18155 !important;
  background-color: #f1815533 !important;
}

.landmark-icon-hover:hover {
  background-color: #f1815533 !important;
}

.essentials-icon {
  color: #ff00d4 !important;
  background-color: transparent !important;
}

.essentials-icon-highlighted {
  color: #ff00d4 !important;
  background-color: #ff00d433 !important;
}

.essentials-icon-hover:hover {
  background-color: #ff00d433 !important;
}

.contrasted {
  background-color: #fff !important;
  border: solid 1px !important;
}
