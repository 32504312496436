.listContainer {
    background-color: #FFFFFFDD;
    border-radius: 10px;
    z-index: 10000;
    margin-top: 8px;
    padding-top: 8px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(117, 110, 110, 0.87);
}

.subCategory {
    margin-left: 5px !important;
    margin-bottom: 5px !important;
    border-radius: 4px;
    background-color: #eaecef;
    color: #6e757c;
    padding: 5px;
    text-transform: capitalize;
}

.selected {
    background-color: #b3b6b9e1 !important;
}

.subCategory:hover {
    cursor: pointer;
    color: rgb(66, 61, 61);
    background-color: #EAECEF;
    border-radius: 4px;
}
