.navContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.list {
  background-color: white;
  scroll-behavior: smooth;
  overflow: scroll;
  height: 35%;
  bottom: 56px;
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 300;
}

.listIndex {
    margin-right: 4px;
}

.detail {
  background-color: white;
  max-height: 40%;
  scroll-behavior: smooth;
  overflow: scroll;
  bottom: 56px;
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 300;
}

.elementListItems:hover {
  background-color: rgb(158, 156, 156);
}

.searchBarMobile {
  position: fixed !important;
  bottom: 56px;
  left: 0;
  right: 0;
  z-index: 300;
}

.listSubHeader {
    display: flex;
    justify-content: space-around;
}

.scrollTopButton {
    width: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 600;
    background-color: white;
}

.scrollTopButton.disabled {
    display: none;
}

.upIcon {
    width: 100%;
}


