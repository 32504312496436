.rightPanel {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 10px;
    margin: 24px;
}

.backGroundIcons {
    background-color: whitesmoke;
    height: 3rem;
    width: 3rem;
    z-index: 3999;
    position: relative;
    border-radius: 50%;
    padding: 0.5rem;
    margin: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.backGroundIcons:hover {
    background-color: grey;
    opacity: 75%;
}


.cityName {
    font-weight: bolder;
    font-size: smaller;
}

.cityExpand {
    background-color: whitesmoke;
    height: 3rem;
    width: 3rem;
    z-index: 3999;
    color: black;
    border-radius: 50%;
    padding: 0.5rem;
    margin: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cityExpand:hover {
    background-color: gray;
    opacity: 80%;
}

.rightIcons {
    position: relative;
    z-index: 4000;
    margin: auto;
    height: 2rem;
    width: 2rem;
    opacity: 100%;
}

#london {
    background-image: url("../../../public/images/flag.kingdom--opacity.png");
}

#paris {
    background-image: url("../../../public/images/flag.france--opacity.png");
}

#new-york {
    background-image: url("../../../public/images/flag.usa--opacity.png");
}
