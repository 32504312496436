.titleHeader {
    padding-left: 25px;
}

.arrowHeaderUp {
    transform: rotate(180deg);
}

.circuitPrimaryText {
    max-width: 50%;
    min-width: 50%;
    word-wrap: break-word;
}

.circuitSecondaryText {
    max-width: 30%;
    min-width: 30%;
    word-wrap: break-word;
    text-align: center;
}

.classeCircuitListNav {
    overflow: scroll;
}
