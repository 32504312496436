.filterContainer {
  border-bottom: solid 1px rgb(156, 154, 154);
  background-color: inherit;
  opacity: 2;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.filterHeader {
  display: flex;
  justify-content: space-around;
}

.arrowHeaderUp {
  transform: rotate(180deg);
}

.filterContent {
  display: flex;
  justify-content: space-between;
}

.filterIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.filterIconText {
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 40px;
    font-size: 10px;
}

.ratingContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
}

.ratingNumber {
    position: absolute;
    font-size: 15px;
    z-index: 10;
    color: #000000;
}

.gold {
  color: #FFD772;
}

.silver {
  color: #979797;
}

.bronze {
  color: #CD7F32;
}
