.gridContainer {
    height: 100%;
}

.locateIcon {
    position: fixed !important;
    bottom: 75px!important;
    right: 25px !important;
    background-color: white !important;
    border: 1px black solid !important;
    z-index: 800;
}

.mobileLocateIcon {
    position: fixed !important;
    top: 35px!important;
    left: 25px !important;
    background-color: white !important;
    border: 1px black solid !important;
    z-index: 800;
}
