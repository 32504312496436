.container {
  padding: 20px;
}

.cardContainer {
  padding: 10px;
}

.headerContainer {
    display: flex;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70%;

}

.previousIcon {
    display: flex;
    align-items: center;
    justify-content: start;

}

.subheader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}

.subSubHeader {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
}

.address {
    display: flex;
    justify-content: center;
}

.categories {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-evenly;
}

.category {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subCategories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 15px;
}

.icons {
  max-width: 20px;
  min-width: 20px;
  border-radius: none;
  background-color: none !important;
}

.icons:hover {
  background-color: none !important;
  cursor: default !important;
}

.websiteButton{
    font-size: 16px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.goThereButton {
  background-color: #2196f3 !important;
  border-radius: 4px !important;
  color: white !important;
  text-transform: none !important;
  margin-top: 5px !important;
}

.placePrices {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    white-space: pre-line;
    vertical-align: bottom;
    margin-bottom: 20px;
    margin-top: 20px;

}

.seeMoreButton {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
