.icons {
    max-width: 20px;
    min-width: 20px;
    border-radius: none;
    background-color: none !important;
}

.headerComponent {
    display: flex;
    width: 100%;
    align-items: center;
    text-transform: capitalize ;
}

.contentComponent {
    margin-left: 22px;
    padding: 0;
}
.cardContainer {
    overflow-x: auto;
}
.shortDescription {
    color: rgb(26, 25, 25);
    padding-bottom: 8px;
}

.steps {
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: underline;
}

.steps:hover {
    cursor: pointer;
}
