.placeIcons {
    max-width: 20px;
    min-width: 20px;
    border-radius: none;
    background-color: none !important;
}

.placeIcons:hover {
    background-color: none !important;
    cursor: default !important;
}

.placeIndexText {
    width: 10%;
    padding-top: 5px;
}

.placeIconsSide {
    max-width: 20%;
    min-width: 20%;
    padding-left: 0% !important;
    display: flex;
    flex-wrap: wrap;
}

.placePrimaryText {
    width: 100%;
    word-wrap: break-word;
}

.selectedCircuit {
    display: flex;
    align-items: center;
    border-bottom: solid 1px gray;
    height: 4rem;
    justify-content: space-around;
}
