.category {
  display: flex;
}

.categoryText {
  font-size: 10px;
  color: gray
}

.sitesCategories {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: center;
}
